import { useCallback, useState, useEffect, useMemo } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Device } from "@capacitor/device";

import { ADD_ONBOARDING, GET_ONBOARDING } from "../queries/user";
import { useSession } from "./auth";
import { usePushNotifications } from "./notifications";
import { localStorageAvailable } from "../utils/detect";


export const CURRENT_ONBOARDING_VERSION = '2021-10-06';

export function useSeenOnboarding() {

  const { user, hasIdToken } = useSession();

  const [ device, setDevice ] = useState({
    appVersion: process.env.REACT_APP_RELEASE_NAME || 'dev',
    onboardingVersion: CURRENT_ONBOARDING_VERSION,
    devicePlatform: '',
    deviceId: '',
  });

  useEffect(() => {
    Device.getInfo().then(info => {
      setDevice(prev => ({ ...prev, devicePlatform: info.platform }));
    }).catch(err => localStorageAvailable && console.error('Device.getInfo', err));
    Device.getId().then(info => {
      setDevice(prev => ({ ...prev, deviceId: info.identifier }));
    }).catch(err => localStorageAvailable && console.error('Device.getId', err));
  }, []);

  const { data, loading, error } = useQuery(GET_ONBOARDING, { skip: !hasIdToken });
  const [addOnboarding, { /*loading: mutationLoading,*/ error: mutationError }] = useMutation(ADD_ONBOARDING);

  if(error) {
    console.error(error);
  }
  if(mutationError) {
    console.error(mutationError);
  }

  const setSeen = useCallback((value) => {
    if(!user || !data) {
      console.error('Calling setSeen before user is available');
      return Promise.reject();
    }
    const input = {
      key: 'seen',
      value: JSON.stringify(value),
      ...device,
    };
    addOnboarding({
      variables: {
        input: input
      },
      optimisticResponse: {
        "addOnboarding":{
          "_id": user._id,
          "__typename":"Person",
          "onboarding":[
            ...data.currentUser.onboarding,
            {
              ...input,
              createdAt: new Date(),
            },
          ],
        }
      }
    })
    return Promise.resolve();
  }, [addOnboarding, data, user, device]);

  const seen = useMemo(() => {
    if(loading) {
      return undefined;
    }
    if(!user) {
      return undefined;
    }
    if(!data) {
      return undefined;
    }
    if(!device.deviceId || !device.devicePlatform) {
      return undefined;
    }

    return data.currentUser.onboarding.some(ob => {
      return ob.key === 'seen' &&
        ob.onboardingVersion === CURRENT_ONBOARDING_VERSION &&
        (ob.devicePlatform === device.devicePlatform || ob.devicePlatform === '*') &&
        (ob.deviceId === device.deviceId || ob.deviceId === '*')
    });
  }, [loading, data, user, device ]);

  return useMemo(() => ({ seen, setSeen }), [ seen, setSeen ]);
}

export function useOnboardingProduct() {

  return true;
}

export function useOnboardingNotifications() {

  const { isPushEnabled } = usePushNotifications();
  return isPushEnabled.enabled
}

export function useBoringOnboarding() {

  const { user } = useSession();
  const [ initialValue, setInitialValue ] = useState(user?.hasSeenPasswordAuth);

  useEffect(() => {
    if(user?.hasSeenPasswordAuth !== undefined && user.hasSeenPasswordAuth !== initialValue) {
      setInitialValue(user.hasSeenPasswordAuth);
    }
  }, [ user, initialValue ]);

  return initialValue;
}
