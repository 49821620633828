import { useCallback, useEffect } from "react";
import { makeVar, useReactiveVar, useApolloClient } from "@apollo/client";

import { GET_WATCH_STATES } from "../queries/user";
import { useReactiveVarWithSelector } from "./reactive-var";
import { useSession } from "./auth";
import { useAnalyticsQueued } from "./delicious-analytics";
import { GET_LIST_INBOX, GET_LIST_INBOX_PAGE_SIZE, GET_WATCHED_PAGE_SIZE, GET_WATCHLIST_PAGE_SIZE } from "../queries/list";
import { GetWatchStatesQuery, useGetWatchStatesQuery, useUpdateWatchStateMutation } from "../generated/graphql";


// using number instead of boolean to allow for semi-watched states
const watchedVar = makeVar<Record<string, number>>({});
const watchlistVar = makeVar<Record<string, number>>({});
const watchStateStatsVar = makeVar<{ watchedCount?: number|null, watchlistCount?: number|null, watchlistNotWatchedCount?: number|null }>({});

let previous: string|null = null;

const MAX_WATCH_STATES = 2000;


export function useRefetchWatchStates() {
  const client = useApolloClient();
  return useCallback(() => {
    client.refetchQueries({
      include: [
        GET_WATCH_STATES,
      ]
    });
  }, [client]);
}


export function useInitializeWatchStates() {
  const { hasIdToken } = useSession();

  const {data, loading} = useGetWatchStatesQuery({
    variables: { first: MAX_WATCH_STATES },
    skip: !hasIdToken,
  });

  useEffect(() => {
    if(!loading && data) {
      if(!data?.currentUser?.watched) {
        console.error('No watched states data returned from server, probably server error. No watched states will be available on client.');
        return;
      }
      const compareString = JSON.stringify(data?.currentUser?.watched) + JSON.stringify(data?.currentUser?.watchlist);
      if(compareString === previous) {
        return;
      }
      const watched = data?.currentUser?.watched?.edges?.reduce((sum, edge) => {
        const item = edge.node;
        if(!item.deleted) {
          if(item.share) {
            sum[item.share._id] = 1;
          }
          if(item.canonicalContent) {
            if(item.seasons?.length) {
              sum[item.canonicalContent._id] = item.seasons.filter(s => s.active).length / item.seasons.length;
              for(const season of item.seasons) {
                sum[item.canonicalContent._id + '-' + season.seasonNumber] = season.active ? 1 : 0;
              }
            } else {
              sum[item.canonicalContent._id] = 1;
            }
          }
        }
        return sum;
      }, {} as Record<string, number>);

      watchedVar(watched);

      const watchlist = data?.currentUser?.watchlist?.edges?.reduce((sum, edge) => {
        const item = edge.node;
        if(!item.deleted) {
          if(item.share) {
            sum[item.share._id] = 1;
          }
          if(item.canonicalContent) {
            sum[item.canonicalContent._id] = 1;
          }
        }
        return sum;
      }, {} as Record<string, number>);

      watchlistVar(watchlist);

      watchStateStatsVar({
        watchedCount: data?.currentUser?.watched?.totalCount,
        watchlistCount: data?.currentUser?.watchlist?.totalCount,
        watchlistNotWatchedCount: data?.currentUser?.watchlist?.edges?.filter(edge => (edge.node.share?._id && !watched[edge.node.share._id]) && (edge.node.canonicalContent?._id && !watched[edge.node.canonicalContent._id])).length,
      });

      previous = compareString;
    }
  }, [data, loading]);
}


export function useIsWatched(shareId: string|undefined, canonicalId: string|undefined, seasonNumber?: number): number {
  return useReactiveVarWithSelector(watchedVar, (watched) => {
    if(canonicalId) {
      if(seasonNumber && !!watched[canonicalId + '-' + seasonNumber]) {
        return 1;
      } else if(!seasonNumber && !!watched[canonicalId]) {
        return watched[canonicalId];
      }
    }
    if(shareId && !!watched[shareId]) {
      return 1;
    }

    return 0;
  })
}


export function useCheckIsWatched() {

  const watched = useReactiveVar(watchedVar);

  const isWatched = useCallback((shareId?: string, canonicalId?: string, seasonNumber?: number): number => {
    if(canonicalId) {
      if(seasonNumber && !!watched[canonicalId + '-' + seasonNumber]) {
        return 1;
      } else if(!seasonNumber && !!watched[canonicalId]) {
        return watched[canonicalId];
      }
    }
    if(shareId && !!watched[shareId]) {
      return 1;
    }

    return 0;
  }, [watched]);

  return isWatched;
}


export function useToggleWatched() {

  const { track } = useAnalyticsQueued();
  const refetch = useRefetchWatchStates();
  const { user } = useSession();

  const [updateWatchState] = useUpdateWatchStateMutation({
    onError: (error) => {
      console.error('Error updating watch state, try to refetch all from server', error);
      refetch();
    },
  });

  const toggleWatchedSeasons = useCallback((canonicalId: string, seasonNumbers: number[], active: boolean, createFeedItems = true) => {
    track('toggle_watched_seasons', { category: 'watch-state', canonicalId, seasonNumbers: seasonNumbers.join(','), active });

    updateWatchState({
      variables: {
        input: {
          canonicalContentId: canonicalId,
          shareId: undefined,
          state: 'watched',
          active,
          createFeedItems,
          seasonNumbers,
        }
      },
      optimisticResponse: {
        updateWatchState: {
          __typename: 'WatchStateItem',
          _id: 'optimistic'+seasonNumbers.join(','),
          deleted: false,
          createdAt: new Date().toISOString(),
          share: null,
          canonicalContent: { _id: canonicalId },
          seasons: seasonNumbers.map(seasonNumber => ({ __typename: 'SeasonWatchState', seasonNumber, active })),
        }
      },
      update: (cache, { data: updateData }) => {
        const updateWatchState = updateData?.updateWatchState;
        if(updateWatchState) {
          // Only evict GET_WATCHED (using GET_WATCHED_PAGE_SIZE argument), not GET_WATCH_STATES that is modified below immediatly
          cache.evict({
            id: cache.identify(user),
            fieldName: 'watched',
            args: { first: GET_WATCHED_PAGE_SIZE },
          });
          cache.gc();
        }

        const data = cache.readQuery<GetWatchStatesQuery>({ query: GET_WATCH_STATES, variables: { first: MAX_WATCH_STATES } });
        const currentUser = data?.currentUser || { _id: user._id, __typename: 'Person', watchlist: { edges: [], pageInfo: { hasNextPage: false, endCursor: null }, totalCount: 0 }, watched: { edges: [], pageInfo: { hasNextPage: false, endCursor: null }, totalCount: 0 } };
        if(!data?.currentUser) {
          console.warn('No watch state data found in cache. Watch state has probably been updated on server but not client.', data);
        }

        // remove existing matching edges
        const oldWatchedEdge = currentUser.watched.edges.find(edge => edge.node.canonicalContent?._id === canonicalId);
        const watchedEdges = currentUser.watched.edges.filter(edge => {
          if(edge.node.canonicalContent?._id === canonicalId) {
            return false;
          }
          return true;
        });

        // add updated edge back in
        if(updateWatchState) {
          const newWatchedEdge = {
            __typename: 'WatchStateEdge' as const,
            cursor: updateWatchState._id,
            // we need to clone the object to make it extensible
            node: {
              ...updateWatchState,
              seasons: updateWatchState.seasons ? [...updateWatchState.seasons] : [],
            },
          };

          // on optimistic response we need to merge the updated seasons with the existing data
          if(updateWatchState._id.startsWith('optimistic') && oldWatchedEdge && !oldWatchedEdge.node.deleted) {
            for(const oldSeason of oldWatchedEdge.node.seasons || []) {
              if(!newWatchedEdge.node.seasons?.some(newSeason => newSeason.seasonNumber === oldSeason.seasonNumber)) {
                newWatchedEdge.node.seasons?.push({ ...oldSeason });
              }
            }
          }

          watchedEdges.push(newWatchedEdge);
        }

        console.log('toggleWatchedSeasons update', {
          oldWatchedEdge,
          updateWatchState,
          watchedEdges,
        })

        cache.writeQuery({
          query: GET_WATCH_STATES,
          variables: { first: MAX_WATCH_STATES },
          data: {
            currentUser: {
              ...currentUser,
              watched: {
                ...currentUser.watched,
                edges: [...watchedEdges],
              }
            }
          }
        });
      }
    });

  }, [track, updateWatchState, user]);

  const toggleWatched = useCallback((canonicalId: string|undefined = undefined, shareId: string|undefined = undefined, active: boolean, createFeedItems = true) => {

    if(active) {
      track('toggle_watched_active', { category: 'watch-state', canonicalId, shareId });
    } else {
      track('toggle_watched_deleted', { category: 'watch-state', canonicalId, shareId });
    }

    updateWatchState({
      variables: {
        input: {
          canonicalContentId: canonicalId,
          shareId: shareId,
          state: 'watched',
          active,
          createFeedItems,
        }
      },
      optimisticResponse: {
        updateWatchState: {
          __typename: 'WatchStateItem',
          _id: 'optimistic',
          deleted: !active,
          createdAt: new Date().toISOString(),
          share: shareId ? { _id: shareId } : null,
          canonicalContent: canonicalId ? { _id: canonicalId } : null,
          seasons: null,
        }
      },
      update: (cache, { data: updateData }) => {
        const updateWatchState = updateData?.updateWatchState;
        if(updateWatchState) {
          if(!active) {
            cache.modify({
              id: cache.identify(updateWatchState),
              fields: {
                deleted() {
                  return !active;
                },
              },
            });
          } else {
            // Only evict GET_WATCHED (using GET_WATCHED_PAGE_SIZE argument), not GET_WATCH_STATES that is modified below immediatly
            cache.evict({
              id: cache.identify(user),
              fieldName: 'watched',
              args: { first: GET_WATCHED_PAGE_SIZE },
            });
            cache.gc();
          }
        }

        const data = cache.readQuery<GetWatchStatesQuery>({ query: GET_WATCH_STATES, variables: { first: MAX_WATCH_STATES } });
        const currentUser = data?.currentUser || { _id: user._id, __typename: 'Person',  watchlist: { edges: [], pageInfo: { hasNextPage: false, endCursor: null }, totalCount: 0 }, watched: { edges: [], pageInfo: { hasNextPage: false, endCursor: null }, totalCount: 0 } };
        if(!data?.currentUser) {
          console.warn('No watch state data found in cache. Watch state has probably been updated on server but not client.', data);
        }

        // remove existing matching edges
        const newWatchedEdges = currentUser.watched.edges.filter(edge => {
          if(shareId && edge.node.share?._id === shareId) {
            return false;
          } else if(canonicalId && edge.node.canonicalContent?._id === canonicalId) {
            return false;
          }
          return true;
        });
        // add edge if active
        if(active && updateWatchState) {
          newWatchedEdges.push({
            __typename: 'WatchStateEdge',
            cursor: updateWatchState._id,
            node: updateWatchState,
          });
        }

        cache.writeQuery({
          query: GET_WATCH_STATES,
          variables: { first: MAX_WATCH_STATES },
          data: {
            currentUser: {
              ...currentUser,
              watched: {
                ...currentUser.watched,
                edges: newWatchedEdges,
              }
            }
          }
        });
      }
    });

  }, [updateWatchState, track, user]);

  return { toggleWatched, toggleWatchedSeasons };
}


export function useIsInWatchlist(...args: (string|undefined)[]) {
  return useReactiveVarWithSelector(watchlistVar, (watchlist) => {
    const ids = args.filter((arg => !!arg) as (arg: string|undefined) => arg is string);
    const isInWatchlist = ids.some(id => !!watchlist[id]);
    return isInWatchlist;
  })
}


export function useCheckIsInWatchlist() {

  const watchlist = useReactiveVar(watchlistVar);

  // useEffect(() => console.log('useCheckIsInWatchlist watchlist', watchlist), [watchlist]);

  const isInWatchlist = useCallback((...args: (string|undefined)[]) => {
    const ids = args.filter((arg => !!arg) as (arg: string|undefined) => arg is string);
    return ids.some(id => !!watchlist[id]);
  }, [watchlist]);

  return isInWatchlist;
}


export function useToggleInWatchlist() {

  const { track } = useAnalyticsQueued();
  const refetch = useRefetchWatchStates();
  const { user } = useSession();

  const [updateWatchState] = useUpdateWatchStateMutation({
    onError: (error) => {
      console.error('Error updating watch state, try to refetch all from server', error);
      refetch();
    },
    refetchQueries: [
      { query: GET_LIST_INBOX, variables: { first: GET_LIST_INBOX_PAGE_SIZE } },
    ],
  });

  const toggleInWatchlist = useCallback((canonicalId: string|undefined, shareId: string|undefined, active: boolean, createFeedItems = true) => {

    if(active) {
      track('toggle_watchlist_active', { category: 'watch-state', canonicalId, shareId });
    } else {
      track('toggle_watchlist_deleted', { category: 'watch-state', canonicalId, shareId });
    }

    updateWatchState({
      variables: {
        input: {
          canonicalContentId: canonicalId,
          shareId: shareId,
          state: 'watchlist',
          active,
          createFeedItems,
        }
      },
      optimisticResponse: {
        updateWatchState: {
          __typename: 'WatchStateItem',
          _id: 'optimistic',
          deleted: !active,
          createdAt: new Date().toISOString(),
          share: shareId ? { _id: shareId } : null,
          canonicalContent: canonicalId ? { _id: canonicalId } : null,
          seasons: null,
        }
      },
      update: (cache, { data: updateData }) => {
        const updateWatchState = updateData?.updateWatchState;
        if(updateWatchState) {
          if(!active) {
            cache.modify({
              id: cache.identify(updateWatchState),
              fields: {
                deleted() {
                  return !active;
                },
              },
            });
          } else {
            // Only evict GET_WATCHLIST (using GET_WATCHLIST_PAGE_SIZE argument), not GET_WATCH_STATES that is modified below immediatly
            cache.evict({
              id: cache.identify(user),
              fieldName: 'watchlist',
              args: { first: GET_WATCHLIST_PAGE_SIZE },
            });
            cache.gc();
          }
        }

        const data = cache.readQuery<GetWatchStatesQuery>({ query: GET_WATCH_STATES, variables: { first: MAX_WATCH_STATES } });
        const currentUser = data?.currentUser || { _id: user._id, __typename: 'Person', watchlist: { edges: [], pageInfo: { hasNextPage: false, endCursor: null }, totalCount: 0 }, watched: { edges: [], pageInfo: { hasNextPage: false, endCursor: null }, totalCount: 0 } };
        if(!data?.currentUser) {
          console.warn('No watch state data found in cache. Watch state has probably been updated on server but not client.', data);
        }


        // remove existing matching edges
        const newWatchlistEdges = currentUser.watchlist.edges.filter(edge => {
          if(shareId && edge.node.share?._id === shareId) return false;
          if(canonicalId && edge.node.canonicalContent?._id === canonicalId) return false;
          return true;
        });
        // add edge if active
        if(active && updateWatchState) {
          newWatchlistEdges.push({
            __typename: 'WatchStateEdge',
            cursor: updateWatchState._id,
            node: updateWatchState,
          });
        }

        cache.writeQuery({
          query: GET_WATCH_STATES,
          variables: { first: MAX_WATCH_STATES },
          data: {
            currentUser: {
              ...currentUser,
              watchlist: {
                ...currentUser.watchlist,
                edges: newWatchlistEdges,
              }
            }
          }
        });
      }
    });

  }, [updateWatchState, track, user]);

  return toggleInWatchlist;
}


export function useWatchStateStats() {

  return useReactiveVar(watchStateStatsVar);

}
