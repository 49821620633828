import { createContext, FC, ReactNode, useContext, useDebugValue } from "react";


const VisibilityContext = createContext(false);


export const VisibilityProvider: FC<{ children: ReactNode, isVisible: boolean }> = function({ children, isVisible }) {
  // const [isVisible, setIsVisible] = useState(false);

  useDebugValue(isVisible ? 'Visible' : 'Hidden');

  return (
    <VisibilityContext.Provider value={isVisible}>
      {children}
    </VisibilityContext.Provider>
  );
}


export const useIsVisible = () => useContext(VisibilityContext);
